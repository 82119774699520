import React, { useCallback } from "react";
import api from "../../api/api";
import { useMutation, useQueryClient } from "react-query";
import { Form, Input, InputNumber, Popconfirm, Select } from "antd";
import { debounce } from "lodash";
import "./GroceryList.styles.scss";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";

const GroceryList = ({ unitChoices, groceryList }) => {
  const { Option } = Select;
  const queryClient = useQueryClient();

  const updateListItem = useMutation(
    (updatedItem) => {
      return api.updateSharedListItem(updatedItem);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sharedGroceryList");
      },
    }
  );

  const deleteListItem = useMutation(
    (itemId) => api.deleteSharedListItem(itemId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sharedGroceryList");
      },
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSave = useCallback(
    debounce((listItemId, updatedValues) => {
      const updatedItem = {
        shared_lists_id: groceryList?.[0]?.shared_lists_id,
        list_items: [{ list_items_id: listItemId, ...updatedValues }],
      };

      updateListItem.mutate(updatedItem);
    }, 1000),
    [groceryList]
  );

  const handleMarkAsDone = (listItemId) => {
    const updatedItem = {
      shared_lists_id: groceryList?.[0]?.shared_lists_id,
      list_items: [{ list_items_id: listItemId, done: true }],
    };

    updateListItem.mutate(updatedItem);
  };

  const handleDelete = (listItemId) => {
    deleteListItem.mutate(listItemId);
  };

  return (
    <div className="gorceryList-container">
      {groceryList?.[0]?.list_items?.map((item) => (
        <Form
          key={item.list_items_id}
          initialValues={{
            list_items_name: item.list_items_name,
            item_qty: item.item_qty,
            quantity_unit: item.quantity_unit,
          }}
          onValuesChange={(changedValues) =>
            handleSave(item.list_items_id, changedValues)
          }
          layout="inline"
          className={item.done === true && "completed-item"}
        >
          <Form.Item className="item_name" name="list_items_name">
            <Input placeholder="Item Name" disabled={item.done === true} />
          </Form.Item>

          <Form.Item className="item_qty" name="item_qty">
            <InputNumber
              min={1}
              placeholder="Quantity"
              disabled={item.done === true}
            />
          </Form.Item>

          <Form.Item
            className="quantity_unit"
            name="quantity_unit"
            disabled={item.done === true}
          >
            <Select
              placeholder="Unit"
              suffixIcon={item.done === true && null}
              disabled={item.done === true}
              dropdownStyle={{ backgroundColor: " #1e1e1e" }}
            >
              {unitChoices?.map((choice) => (
                <Option key={choice?.label} value={choice?.value}>
                  {choice?.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="action-buttons">
            <Popconfirm
              overlayClassName="pop-confirm"
              title="Are you sure you want to delete this item?"
              onConfirm={() => handleDelete(item.list_items_id)}
              okText="Yes"
              cancelText="No"
              okType="text"
              okButtonProps={{ className: "yes-btn" }}
              cancelButtonProps={{ type: "text" }}
            >
              <span className="btn-container delete">
                <DeleteOutlined />
              </span>
            </Popconfirm>
            <span className="btn-container done">
              <CheckOutlined
                onClick={
                  item.done === false
                    ? () => handleMarkAsDone(item.list_items_id)
                    : null
                }
              />
            </span>
          </div>
        </Form>
      ))}
    </div>
  );
};

export default GroceryList;
