import React, { useState } from "react";
import { useQuery } from "react-query";
import api from "../../api/api";
import { useLocation, useParams } from "react-router";
import { Carousel, Divider, Empty, Modal, Spin } from "antd";
import CaptainUserCard from "../CaptainUserCard/CaptainUserCard.component";
import map from "../../assets/images/Map.png";
import gallery from "../../assets/images/SVG/gallery.svg";
import "./HubProfileDetails.styles.scss";
import CrewListDropDown from "../CrewListDropDown/CrewListDropDown.component";
import MyGoogleMap from "../GoogleMap/GoogleMap";
import { useUserContext } from "../../context/UserContext.js";

const HubProfileDetailsComponent = () => {
  const URL = useLocation();
  const { hubId } = useParams();
  const isEditHubPage = URL.pathname.includes("edit-hub");
  const [location, setLocation] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { currentUser } = useUserContext();

  const { data: hubProfileDetails, isLoading } = useQuery(
    [`hubProfileDetails-${hubId}`, hubId],
    async () => await api.getHubProfileDetails(hubId)
  );
  console.log({ hubProfileDetails });

  const { data: hubData } = useQuery(
    [`hubData-${hubId}`, hubId],
    async () => await api.getHubDetails(hubId)
  );
  console.log({ hubData });

  const { data: userProfileDetails } = useQuery(
    [
      `userProfileDetails-${hubData?.admins[0]?.admin_id}`,
      hubData?.admins[0]?.admin_id,
    ],
    async () => await api.getProfileDetails(hubData?.admins[0]?.admin_id)
  );
  console.log({ userProfileDetails });

  const { data: hubCrewList } = useQuery(
    [`hubCrewList-${hubId}`, hubId],
    async () => await api.getHubCrew(hubId)
  );
  console.log({ hubCrewList });

  const showMap = () => {
    const fetchedLocation = hubProfileDetails?.location;
    if (fetchedLocation && fetchedLocation.length === 2) {
      const latitude = parseFloat(fetchedLocation[0]);
      const longitude = parseFloat(fetchedLocation[1]);
      setLocation([latitude, longitude]);
      setCurrentPosition({ latitude, longitude });
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function capitalizeAndJoin(value) {
    return value
      ?.map((item) => {
        return item.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase());
      })
      .join(", ");
  }

  function getItemStatus(item) {
    // Check if enable is false
    if (!item?.enable) {
      return (
        <div className="value-container">
          <span className="value red">Unavailable</span>
        </div>
      );
    }
    // Check if enable is true but included_in_rent is false
    else if (item?.enable && !item?.included_in_rent) {
      return (
        <div className="value-container">
          <span className="value">Available</span>
          <span className="status-red">Not included in rent</span>
        </div>
      );
    }
    // Default case: enabled and included_in_rent is true
    else {
      return (
        <div className="value-container">
          <span className="value">Included in rent</span>
        </div>
      );
    }
  }

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const defaultCenter = {
    lat: currentPosition?.latitude || 0,
    lng: currentPosition?.longitude || 0,
  };

  const windowWidth = window.innerWidth;

  const numberOfImages =
    windowWidth > 1000 ? 3 : windowWidth < 1000 && windowWidth > 600 ? 2 : 1;

  return (
    <div className={`hubProfile-container ${isEditHubPage && "addon-style"}`}>
      {isLoading ? (
        <Spin className="spin" size="large" />
      ) : (
        <>
          {hubProfileDetails?.image_urls?.length !== 0 ? (
            <Carousel
              autoplay
              slidesToShow={numberOfImages}
              arrows
              // infinite={false}
            >
              {hubProfileDetails?.image_urls?.map((url, index) => (
                <div key={index}>
                  <img
                    style={{ margin: "auto", height: "300px", width: "400px" }}
                    src={url}
                    alt="hub-img"
                  />
                </div>
              ))}
            </Carousel>
          ) : (
            <div className="no-image-container">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No images to display"
              />
              ;
            </div>
          )}

          <div className="photos-crew">
            <div className="photos">
              <img src={gallery} alt="photos" />
              <span>All Photos</span>
            </div>
            <CrewListDropDown
              crewList={hubCrewList}
              hubData={hubData}
              currentUser={currentUser}
            />
          </div>
          <Divider
            className="hub-divider"
            style={{
              borderBlockStart: "2px solid #353542",
            }}
          />
          <div className="hub-view">
            <div className="hub-details">
              <div className="name-address-container">
                <div className="name-address">
                  <h1 className="name">{hubData?.hub_name}</h1>
                  <p>{hubProfileDetails?.address}</p>
                </div>
                {hubProfileDetails?.location && (
                  <div className="map" onClick={showMap}>
                    <img src={map} alt="map" />
                    <span>Map</span>
                  </div>
                )}
              </div>
              <Divider style={{ borderBlockStartColor: "#353542" }} />
              {hubProfileDetails?.hub_description && (
                <div className="item-container">
                  <span className="title">Description</span>
                  <span className="description white">
                    {hubProfileDetails?.hub_description}
                  </span>
                  {/* <Button className="load-btn" type="text">
                    + Load more...
                  </Button> */}
                </div>
              )}
              <div className="item-container">
                <span className="title">Basic information</span>
                <div className="item">
                  <span className="label">Type</span>
                  <div className="value-container">
                    {hubProfileDetails?.home_type && (
                      <span className="value">
                        {hubProfileDetails?.home_type?.charAt(0).toUpperCase() +
                          hubProfileDetails?.home_type?.slice(1)}
                      </span>
                    )}
                  </div>
                </div>
                <div className="item">
                  <span className="label">Bedrooms</span>
                  <div className="value-container">
                    <span className="value">
                      {hubProfileDetails?.no_of_bedrooms}
                    </span>
                  </div>
                </div>
                <div className="item">
                  <span className="label">Bathrooms</span>
                  <div className="value-container">
                    <span className="value">
                      {hubProfileDetails?.no_of_bathrooms}
                    </span>
                  </div>
                </div>
                <div className="item">
                  <span className="label">Max housemates</span>
                  <div className="value-container">
                    <span className="value">{hubData?.maximum_crew}</span>
                  </div>
                </div>
              </div>
              <div className="item-container">
                <span className="title">Utilities</span>
                <div className="item">
                  <span className="label">Amenities</span>
                  <div className="value-container">
                    <span className="value">
                      {capitalizeAndJoin(
                        hubProfileDetails?.available_kitchen_amenities
                      )}
                    </span>
                  </div>
                </div>
                {hubProfileDetails?.air_conditioning?.map((item) => (
                  <div className="item" key={item.choice}>
                    <span className="label">
                      {item.choice
                        .replace(/_/g, " ")
                        .replace(/^\w/, (c) => c.toUpperCase())}
                    </span>
                    {getItemStatus(item)}
                  </div>
                ))}
                {hubProfileDetails?.other_utilities?.map((utility) => (
                  <div className="item" key={utility.choice}>
                    <span className="label">
                      {utility.choice.charAt(0).toUpperCase() +
                        utility.choice.slice(1)}
                    </span>
                    {getItemStatus(utility)}
                  </div>
                ))}
              </div>
              <div className="item-container">
                <span className="title">Services</span>
                <div className="item">
                  <span className="label">Parking</span>
                  {getItemStatus(hubProfileDetails?.parking)}
                </div>
                <div className="item">
                  <span className="label">Storage</span>
                  {getItemStatus(hubProfileDetails?.storage_space)}
                </div>
                <div className="item">
                  <span className="label">Furnishing</span>
                  {getItemStatus(hubProfileDetails?.furniture)}
                </div>
                {hubProfileDetails?.laundry?.map((item) => (
                  <div className="item" key={item.choice}>
                    <span className="label">
                      {item.choice
                        .replace(/_/g, " ")
                        .replace(/^\w/, (c) => c.toUpperCase())}
                    </span>
                    {getItemStatus(item)}
                  </div>
                ))}
              </div>
              <div className="item-container">
                <span className="title">Additional information</span>
                <div className="item">
                  <span className="label">Pet policy</span>
                  <div className="value-container">
                    <span className="value">
                      {hubProfileDetails?.pets
                        ?.replace(/_/g, " ")
                        .replace(/^\w/, (c) => c.toUpperCase())}
                    </span>
                  </div>
                </div>
                <div className="item">
                  <span className="label">Smoking policy</span>
                  <div className="value-container">
                    <span className="value">
                      {hubProfileDetails?.smoking
                        ?.replace(/_/g, " ")
                        .replace(/^\w/, (c) => c.toUpperCase())}
                    </span>
                  </div>
                </div>
                <div className="item">
                  <span className="label">Other policies</span>
                  <div className="value-container">
                    <span className="value">
                      {capitalizeAndJoin(
                        hubProfileDetails?.additional_policies
                      )}
                    </span>
                  </div>
                </div>
                <div className="item">
                  <span className="label">Noise level</span>
                  <div className="value-container">
                    <span className="value">{hubProfileDetails?.noise}</span>
                  </div>
                </div>
                <div className="item">
                  <span className="label">Places of interest</span>
                  <div className="value-container">
                    <span className="value">
                      {capitalizeAndJoin(hubProfileDetails?.place_of_interest)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {hubData?.admins.length !== 0 && (
              <div className="hub-captain">
                <CaptainUserCard
                  crew={hubData?.admins[0]}
                  details={userProfileDetails?.profile_details}
                  currentUser={currentUser}
                />
              </div>
            )}
          </div>
        </>
      )}
      <Modal
        className="map-modal"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
      >
        {location.length > 0 && (
          <MyGoogleMap
            position={currentPosition}
            mapContainerStyle={mapContainerStyle}
            center={defaultCenter}
          />
        )}
      </Modal>
    </div>
  );
};

export default HubProfileDetailsComponent;
