import {
  Avatar,
  Button,
  Form,
  Input,
  Radio,
  Select,
  Slider,
  Switch,
  Tag,
  message,
} from "antd";
import EmojiPicker from "emoji-picker-react";
import addEmojiIcon from "../../../assets/images/SVG/emojiAdd.svg";
import { useMutation, useQueryClient } from "react-query";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import dayjs from "dayjs";
import profileAvatar from "../../../assets/images/SVG/avatar.svg";
import WeekdaySelector from "../../WeekdaySelector/WeekdaySelector.component";
import calender from "../../../assets/images/SVG/calender.svg";
import api from "../../../api/api";
import { dayAbbreviations, daysOfWeek, emojiList } from "../../../variables";
import "./AssignmentForm.styles.scss";
import TagManager from "react-gtm-module";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const DatePicker = generatePicker(dayjsGenerateConfig);

const AssignmentForm = ({
  form,
  isEdit,
  initialValues,
  setVisible,
  dayNamesMap,
  selectedDays,
  setSelectedDays,
  isEditingSeries,
  showWeekdaySelector,
  setShowWeekdaySelector,
  setCompressed,
  toDoPage,
}) => {
  const { hubId } = useParams();
  const queryClient = useQueryClient();
  const crewList = queryClient.getQueryData(["hubCrew", hubId]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [newEmojiList, setNewEmojiList] = useState(emojiList);
  // const [showClassName, setShowClassName] = useState(false);

  // const [selectedDays, setSelectedDays] = useState([]);

  const onEmojiClick = (emojiObject) => {
    const selectedEmojiObject = {
      value: emojiObject?.names[0], // Assuming names array is present in emojiObject
      emoji: emojiObject?.emoji,
    };
    // Add the selected emoji object to the emoji list
    newEmojiList?.length <= 5
      ? setNewEmojiList((prevEmojiList) => [
          ...prevEmojiList,
          selectedEmojiObject,
        ])
      : setNewEmojiList((prevEmojiList) => {
          const updatedList = [...prevEmojiList];
          updatedList.pop(); // Remove the last added emoji
          return [...updatedList, selectedEmojiObject];
        });
    setShowEmojiPicker(false);
  };

  const handleDisabledDate = (current) => {
    const today = dayjs().startOf("day");
    const ninetyDaysFromToday = today.add(90, "days");
    return current && (current < today || current > ninetyDaysFromToday);
  };

  const handleEditMutationSuccess = () => {
    // Invalidate relevant queries after a successful mutation
    queryClient.invalidateQueries(["hubAssignments", toDoPage, 1]);
    queryClient.invalidateQueries("currentUser");
    message.success(
      `${
        isEditingSeries ? "Assignment Series" : "Assignment"
      } updated successfully`
    );
  };

  const onError = (error) => {
    console.error("Error during mutation:", error);
    const errorMessage = isEdit
      ? "Error updating assignment"
      : "Error adding assignment";
    message.error(errorMessage);
  };

  // Define a mutation function with the createAssignments API call
  const createTaskMutation = useMutation(
    async (values) => {
      const response = await api.createAssignments(values);
      return response?.data;
    },
    {
      // onSuccess will be called after a successful mutation
      onSuccess: (data) => {
        console.log(data);
        queryClient.invalidateQueries(["hubAssignments", 1, 1]);
        queryClient.invalidateQueries("currentUser");
        message.success("Assignment added succesfully");

        const DAAATA = Array.isArray(data) ? data[0] : data;

        const successData = data?.length > 1 ? data[0] : DAAATA;

        const selectedCrew = successData?.assigned_to?.map((crew) =>
          crew.full_name.toUpperCase()
        );
        let assignmentData;

        if (successData?.repeat_id) {
          // If repeat_id exists, construct assignmentData accordingly
          assignmentData = {
            name: successData?.assignment_name,
            emoji: successData?.assignment_emoji_grouping,
            repeatTillDate: successData?.repeat_till,
            assignedCrew: selectedCrew,
            points: successData?.assignment_points,
            repeatsOn: successData?.week_days,
          };
        } else {
          // If repeat_id does not exist, construct assignmentData accordingly
          assignmentData = {
            name: successData?.assignment_name,
            emoji: successData?.assignment_emoji_grouping,
            toDoDate: successData?.to_do_on,
            assignedCrew: selectedCrew,
            points: successData?.assignment_points,
          };
        }

        console.log(assignmentData);

        const eventData = {
          gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
          dataLayer: {
            event: "AssignmentSubmission",
            AssignmentData: assignmentData,
          },
        };

        // Push data to the dataLayer
        TagManager.dataLayer(eventData);
      },
      onError,
    }
  );

  const editAssignmentMutation = useMutation(
    async ({ assignmentId, values }) => {
      const response = await api.editAssignment(assignmentId, values);
      return response?.data;
    },
    {
      onSuccess: handleEditMutationSuccess,
      onError,
    }
  );

  const seriesEditAssignmentMutation = useMutation(
    async (values) => {
      const response = await api.seriesEditAssignment(values);
      return response?.data;
    },
    {
      onSuccess: handleEditMutationSuccess,
      onError,
    }
  );

  const onFinish = (values) => {
    try {
      // Convert the selected participant names to participant objects
      const selectedCrews = crewList?.filter((crew) =>
        values?.assigned_to.includes(crew?.full_name)
      );
      // Create an array of participant IDs to include in the request payload
      const selectedParticipantIDs = selectedCrews?.map(
        (crew) => crew?.crew_id
      );

      // Update the "assigned_to" field in the values object with the selected participant IDs
      values.assigned_to = selectedParticipantIDs;
      values.to_do_on = values.to_do_on
        ? dayjs(values.to_do_on).format("YYYY-MM-DD")
        : null;
      values.repeat_till = values.repeat_till
        ? dayjs(values.repeat_till).format("YYYY-MM-DD")
        : null;

      const selectedWeekdays = selectedDays?.map(
        (dayAbbrev) => dayNamesMap[dayAbbrev]
      );

      values.week_days = selectedWeekdays;
      // values.repeat_till = values?.repeat_till && values?.repeat_till;

      const formData = {
        ...values,
        status: "To do",
        assignment_emoji_grouping: values.assignment_emoji_grouping,
        repeat_id: initialValues?.repeat_id,
        repeat_till: initialValues?.repeat_till
          ? dayjs(initialValues.repeat_till).format("YYYY-MM-DD")
          : null,
        [showWeekdaySelector ? "repeat_till" : "to_do_on"]:
          isEdit && showWeekdaySelector ? values.repeat_till : values.to_do_on,
      };

      if (!isEdit) {
        createTaskMutation.mutate(formData);
      } else {
        if (isEditingSeries) {
          const seriesFormData = {
            ...formData,
            repeat_till: showWeekdaySelector
              ? dayjs(values?.repeat_till).format("YYYY-MM-DD")
              : null,
          };
          seriesEditAssignmentMutation.mutate(seriesFormData);
        } else {
          editAssignmentMutation.mutate({
            assignmentId: initialValues?.assignment_id,
            values: formData,
          });
        }
      }

      // You can reset the form or perform other actions after a successful mutation
      form.resetFields();
      setSelectedDays([]);
      setVisible(false);
      setShowWeekdaySelector(false);
      setNewEmojiList(emojiList);
      setCompressed(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const calenderImg = (
    <img src={calender} alt="calender" width="20px" height="20px" />
  );

  function tagRender(props) {
    const { label, value, closable, onClose } = props;
    const selectedCrew = crewList?.find((crew) => crew.full_name === label);

    return (
      <Tag
        color={value}
        closable={closable}
        onClose={onClose}
        className="crew-tag"
      >
        <Avatar
          className="marginSide"
          src={
            selectedCrew?.profile_picture_url === null ||
            selectedCrew?.profile_picture_url.endsWith("default.jpg")
              ? profileAvatar
              : selectedCrew?.profile_picture_url
          }
        />
        <span style={{ textTransform: "capitalize", marginLeft: 5 }}>
          {selectedCrew?.full_name}
        </span>
      </Tag>
    );
  }

  const dateFormat = "dddd, MMM DD";
  const customFormat = (value) =>
    `${showWeekdaySelector ? "Repeats until" : "Deadline on"}: ${value.format(
      dateFormat
    )}`;

  useEffect(() => {
    if (isEdit) {
      console.log({ initialValues });
      // Extract the weekdays from the initialValues and convert to abbreviations
      const weekdays =
        initialValues?.week_days?.map((day) => dayAbbreviations[day]) || [];
      setSelectedDays(weekdays);
      form.setFieldsValue({
        ...initialValues,
        repeat_till: initialValues?.repeat_till
          ? dayjs(initialValues?.repeat_till)
          : null,
        to_do_on: initialValues?.to_do_on
          ? dayjs(initialValues?.to_do_on)
          : null,
      });
      // } else {
      //   form.setFieldsValue({
      //     ...initialValues,
      //   });

      const initialEmoji = initialValues?.assignment_emoji_grouping;

      // Check if the emoji from initialValues is in newEmojiList
      const emojiExists = newEmojiList.some(
        (emoji) => emoji.emoji === initialEmoji
      );

      if (!emojiExists) {
        // If the initial emoji is not in the emoji list and the list is at its maximum length
        if (newEmojiList.length >= 6) {
          setNewEmojiList((prevEmojiList) => [
            ...prevEmojiList.slice(0, 5),
            { value: initialEmoji, emoji: initialEmoji },
          ]);
        } else {
          // If the initial emoji is not in the emoji list and the list length is less than 6
          setNewEmojiList((prevEmojiList) => [
            ...prevEmojiList,
            { value: initialEmoji, emoji: initialEmoji },
          ]);
        }
      }

      // If the emoji list length is more than 6, remove the last added emoji
      if (newEmojiList.length > 5) {
        setNewEmojiList((prevEmojiList) => prevEmojiList.slice(0, 5));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isEdit, initialValues, isEditingSeries, setSelectedDays]);

  const getRepeatActiveStatus = useCallback(() => {
    if (showWeekdaySelector) {
      return true;
    }
    return isEditingSeries;
  }, [isEditingSeries, showWeekdaySelector]);

  const handleRepeatStatusChange = useCallback(
    (checked) => {
      if (!checked) {
        setSelectedDays([]);
      }
      setShowWeekdaySelector((prev) => {
        return checked;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedDays]
  );

  useEffect(() => {
    setShowWeekdaySelector(isEditingSeries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingSeries]);

  // let repeatTill = form.getFieldValue("repeat_till")
  //   ? dayjs(form.getFieldValue("repeat_till")).format("MMMM DD")
  //   : dayjs(form.getFieldValue("to_do_on")).format("MMMM DD");

  // // Handle case where repeatTill is "Invalid Date"
  // if (repeatTill === "Invalid Date") {
  //   repeatTill = form.setFieldValue(["repeat_till"], null);
  // }

  // console.log({ repeatTill });

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        // initialValues={initialValues}
        className="assignment-form"
        requiredMark={false}
        layout="vertical"
      >
        <div className="form-input-box">
          <Form.Item
            label="Assignment Type"
            required={false}
            name="assignment_emoji_grouping"
            rules={[
              { required: true, message: "Please select assignment type" },
            ]}
          >
            <Radio.Group className="emoji-group" buttonStyle="solid">
              {newEmojiList.map((emoji, index) => (
                <Radio.Button key={index} value={emoji.emoji}>
                  {emoji.emoji}
                </Radio.Button>
              ))}
              <Button
                className="add-emoji"
                onClick={() => setShowEmojiPicker(true)}
              >
                <img src={addEmojiIcon} alt="addEmoji" />
              </Button>
            </Radio.Group>
          </Form.Item>
          {showEmojiPicker && (
            <EmojiPicker
              className="emoji-picker"
              onEmojiClick={onEmojiClick}
              style={{ width: "96%" }}
            />
          )}
        </div>
        <div className="form-input-box">
          <Form.Item
            label="Assignment Name"
            required={false}
            name="assignment_name"
            rules={[
              { required: true, message: "Please input assignment name!" },
              {
                max: 15,
                message: "Assignment name must be less than 15 characters !",
              },
            ]}
          >
            <Input placeholder="Describe your assignment in 1-2 words" />
          </Form.Item>
        </div>
        <div className="form-input-box">
          <Form.Item
            label="Description"
            required={false}
            name="assignment_description"
            rules={[
              {
                required: true,
                message: "Please input assignment description!",
              },
              {
                max: 40,
                message:
                  "Assignment description must be less than 40 characters !",
              },
            ]}
          >
            <Input placeholder="Describe your assignment" />
          </Form.Item>
        </div>
        <div className="form-input-box">
          <Form.Item
            label="Points"
            required={false}
            name="assignment_points"
            rules={[{ required: true, message: "Please input points" }]}
          >
            <Slider className="point-slider" min={1} max={10} />
          </Form.Item>
        </div>
        <div className="form-input-box">
          <Form.Item
            label="Choose crew"
            required={false}
            name="assigned_to"
            rules={[{ required: true, message: "Please choose a crew" }]}
          >
            <Select
              className="crew-dropdown"
              placeholder="Choose crew from the list"
              mode="tags"
              optionLabelProp="label"
              dropdownStyle={{
                backgroundColor: "#1e1e1e",
                fontFamily: "inherit",
              }}
              tagRender={tagRender}
            >
              {crewList?.map((crew) => (
                <Select.Option
                  key={crew?.crew_id}
                  value={crew?.full_name}
                  label={crew?.full_name}
                >
                  <div className="crew-member">
                    <Avatar
                      className="marginSide"
                      src={
                        crew?.profile_picture_url === null ||
                        crew?.profile_picture_url.endsWith("default.jpg")
                          ? profileAvatar
                          : crew?.profile_picture_url
                      }
                    />
                    <span
                      style={{ textTransform: "capitalize", color: "white" }}
                    >
                      {crew?.full_name}
                    </span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="form-input-box">
          <Form.Item
            label={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <span>Schedule</span>
                  {(!isEdit ||
                    isEditingSeries ||
                    (isEdit && !initialValues?.repeat_id)) && (
                    <Form.Item
                      name="repeat"
                      label="Repeat"
                      className="switch-container"
                    >
                      <Switch
                        checked={showWeekdaySelector}
                        defaultChecked={getRepeatActiveStatus()}
                        // value={getRepeatActiveStatus()}
                        onChange={handleRepeatStatusChange}
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
            }
            name={isEdit && showWeekdaySelector ? "repeat_till" : "to_do_on"}
            rules={[{ required: true, message: "Select a date" }]}
          >
            <DatePicker
              format={customFormat}
              suffixIcon={calenderImg}
              placeholder={`${
                showWeekdaySelector ? "Repeats until" : "Choose date"
              }`}
              disabledDate={handleDisabledDate}
            />
          </Form.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {showWeekdaySelector ? (
              <WeekdaySelector
                daysOfWeek={daysOfWeek}
                selectedDays={selectedDays}
                setSelectedDays={setSelectedDays}
                // dayNamesMap={dayNamesMap}
                // repeatTill={repeatTill}
              />
            ) : null}
          </div>
        </div>
        <Button
          type="primary"
          style={{ width: "100%" }}
          shape="round"
          className="btn-blue"
          htmlType="submit"
        >
          {isEdit ? "Save" : "Create"}
        </Button>
      </Form>
    </>
  );
};

export default AssignmentForm;
