import { Button, Form, Input, Modal, message } from "antd";
import { useState } from "react";
import { useUserContext } from "../../context/UserContext";
import {
  deleteUser,
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from "firebase/auth";
import { auth } from "../../api/firebase.js";
import api from "../../api/api.js";
import { useNavigate } from "react-router";
import "./DeleteUser.styles.scss";

const DeleteUser = () => {
  const { confirm } = Modal;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { currentUser } = useUserContext();
  const [showForm, setShowForm] = useState(false);
  const user = auth?.currentUser;
  const login_type = user?.providerData[0]?.providerId;
  const emailUser = login_type === "password";

  const provider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider("apple.com");

  const reauthentication = async (values) => {
    try {
      if (login_type === "google.com") {
        const res = await reauthenticateWithPopup(user, provider);
        const credential = GoogleAuthProvider.credentialFromResult(res);
        return credential;
      } else if (login_type === "apple.com") {
        const res = await reauthenticateWithPopup(user, appleProvider);
        const credential = OAuthProvider.credentialFromResult(res);
        return credential;
      } else if (emailUser) {
        const credential = EmailAuthProvider.credential(
          values.email,
          values.password
        );
        return credential;
      }
    } catch (error) {
      console.error("Error in reauthentication:", error);
      throw error;
    }
  };

  const deleteUserData = async (user, values) => {
    try {
      setLoading(true);
      const credential = await reauthentication(values);
      await reauthenticateWithCredential(user, credential);
      await deleteUser(user);
      await api.deleteUserData();
      message.success("Deleted userdata successfully");
      navigate("/");
    } catch (error) {
      console.error("Error deleting user:", error);
      message.error("Error deleting user");
    }
  };

  const onFinish = (values) => {
    deleteUserData(user, values);
    form.resetFields();
  };
  const showConfirm = (values) => {
    if (values?.email !== currentUser?.email) {
      form.setFields([
        {
          name: "email",
          errors: ["Email does not match the current user's email"],
        },
      ]);
      return;
    }
    confirm({
      title: "Confirmation",
      content:
        "Are your sure you wish to proceed with a complete data deletion?",
      icon: null,
      okText: "Confirm",
      okButtonProps: {
        className: "delete-user-confirm",
      },
      okType: "text",
      cancelText: "Cancel",
      cancelButtonProps: { type: "text" },
      onOk() {
        onFinish(values);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div className="delete-container">
      <h1 style={{ color: "white" }}>Delete your data</h1>

      {!showForm ? (
        <>
          <p className="delete-content">
            This process will unassign all your tasks, remove you from your
            current hub, and delete any relevant user information related to
            this email.
          </p>
          <h3 className="delete-confirmation">Would you like to continue?</h3>
          <Button
            className="caution-btn"
            shape="round"
            onClick={() => setShowForm(true)}
          >
            Proceed with Caution
          </Button>
        </>
      ) : emailUser ? (
        <Form
          form={form}
          name="basic"
          style={{
            maxWidth: 600,
          }}
          onFinish={(values) => showConfirm(values)}
          autoComplete="off"
        >
          <p className="form-content">
            Please re-enter your email & password to continue
          </p>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input placeholder="Enter your email here" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter password!",
              },
            ]}
          >
            <Input.Password type="password" placeholder="Enter your password" />
          </Form.Item>

          <Button
            className="deleteData-btn"
            shape="round"
            htmlType="submit"
            loading={loading}
          >
            Delete all data
          </Button>
          <Button
            className="back-btn"
            type="text"
            onClick={() => {
              navigate(`/user/${currentUser?.crew_id}/settings`);
              setShowForm(false);
              form.resetFields();
            }}
          >
            Back
          </Button>
        </Form>
      ) : (
        <div>
          <p className="delete-content">
            To delete data, you may need to log in again for reauthentication.
            This ensures the security of your account.
          </p>
          <Button
            className="deleteData-btn"
            shape="round"
            onClick={() => deleteUserData(user)}
            loading={loading}
          >
            Delete all data
          </Button>
          <Button
            className="back-btn"
            type="text"
            onClick={() => {
              navigate(`/user/${currentUser?.crew_id}/settings`);
              setShowForm(false);
            }}
          >
            Back
          </Button>
        </div>
      )}
    </div>
  );
};

export default DeleteUser;
