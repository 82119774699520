import { Avatar, Card, Drawer, Modal, message, Dropdown } from "antd";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import addUserIcon from "../../assets/images/SVG/AddUserIcon.svg";
import api from "../../api/api";
import { useState } from "react";
import AddCrew from "../AddCrew/AddCrew.component";
import "./CrewList.styles.scss";
import UserProfileDetailsComponent from "../UserProfileDetails/UserProfileDetails.component";

const CrewList = () => {
  const { confirm } = Modal;
  const { hubId } = useParams();
  const navigate = useNavigate();
  const [clickTimeout, setClickTimeout] = useState(null);
  const [visible, setVisible] = useState(false);
  const queryClient = useQueryClient();
  const hubData = queryClient.getQueryData(["hubDetails", hubId]);
  const adminIds = hubData?.admins?.map((admin) => admin.admin_id);
  const { data: hubCrew } = useQuery(["hubCrew", hubId], async () => {
    const hubData = await api.getHubCrew(hubId);
    return hubData;
  });
  const currentUser = queryClient.getQueryData("currentUser");
  const userId = currentUser?.crew_id;
  const isUserAdmin = adminIds?.includes(currentUser?.crew_id);
  const toCamelCase = (str) => {
    return str?.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  // Check if crewList is an array before mapping over it
  const crewListToMap = Array.isArray(hubCrew) ? hubCrew : [];

  // Sort crew members based on whether they are admins
  const sortedCrewList = [...crewListToMap].sort((a, b) => {
    const isAdminA = adminIds?.includes(a.crew_id);
    const isAdminB = adminIds?.includes(b.crew_id);

    // Both are admins or both are not admins, maintain the original order
    if ((isAdminA && isAdminB) || (!isAdminA && !isAdminB)) {
      return 0;
    }
    // A is an admin but B is not, place A before B
    else if (isAdminA && !isAdminB) {
      return -1;
    }
    // B is an admin but A is not, place B before A
    else {
      return 1;
    }
  });

  const removeCrewMember = useMutation(
    async (crew) => {
      // Perform the API call to remove the crew member from the hub
      const response = await api.RemoveCrewFromHub(hubId, crew?.crew_id);
      if (response === undefined) {
        return message.error("Error removing crew member");
      }

      // Return the updated hub data or any relevant response
      return { crew };
    },
    {
      // onSuccess will be called after a successful mutation
      onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["hubCrew", hubId]);
        message.success(
          `${toCamelCase(
            data?.crew?.full_name
          )} have been removed successfully from the hub`
        );
      },
    }
  );

  const handleDeleteParticipant = (crew) => {
    // Trigger the mutation to remove the crew member
    removeCrewMember.mutateAsync(crew);
  };

  const makeAdmin = async (crew) => {
    try {
      const response = await api.makeAdmin(hubId, {
        new_admin_id: crew.crew_id,
      });
      if (response.status === 200) {
        queryClient.invalidateQueries(["hubDetails", hubId]);
        queryClient.invalidateQueries(["hubCrew", hubId]);
        queryClient.invalidateQueries("currentUser");
        message.success(`${toCamelCase(crew.full_name)} is now a captain`);
      }
    } catch (error) {
      console.error(error);
      message.error("Error while assigning admin");
    }
  };

  const removeAdmin = async (crew) => {
    try {
      const response = await api.removeAdmin(hubId, {
        admin_id: crew.crew_id,
      });
      if (response.status === 200) {
        queryClient.invalidateQueries(["hubDetails", hubId]);
        queryClient.invalidateQueries(["hubCrew", hubId]);
        queryClient.invalidateQueries("currentUser");

        message.success(
          `Admin privileges have been removed successfully for ${toCamelCase(
            crew?.full_name
          )}`
        );
      }
    } catch (error) {
      console.error(error);
      message.error(error?.message || "Error while removing admin");
    }
  };

  const showConfirm = ({ content, onOk }) => {
    confirm({
      title: "Confirmation",
      content,
      icon: null,
      okText: "Confirm",
      okType: "text",
      cancelText: "Cancel",
      cancelButtonProps: { type: "text" },
      onOk,
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showDeleteConfirm = (crew) => {
    const content = `Are you sure to remove ${toCamelCase(
      crew.full_name
    )} from the hub?`;
    showConfirm({ content, onOk: () => handleDeleteParticipant(crew) });
  };

  const showMakeAdminConfirm = (crew) => {
    const content = `Are you sure that you want to make ${toCamelCase(
      crew.full_name
    )} as captain?`;
    showConfirm({ content, onOk: () => makeAdmin(crew) });
  };

  const showRemoveAdminConfirm = (crew) => {
    const content = `Are you sure that you want to remove ${toCamelCase(
      crew.full_name
    )} as captain?`;
    showConfirm({ content, onOk: () => removeAdmin(crew) });
  };

  const handleClick = (crew, e) => {
    // Handle click event with a slight delay for distinguishing between click and double-click
    e.stopPropagation();
    const timeoutId = setTimeout(() => {
      // If no double-click, trigger the dropdown logic
      setClickTimeout(null);
      // Add logic for dropdown or single-click actions here
    }, 200); // 200ms delay to wait for potential double-click
    setClickTimeout(timeoutId);
  };

  const handleDoubleClick = (crew, e) => {
    // If there's a single-click timeout, clear it to prevent the single-click action
    e.stopPropagation();
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    }

    // Trigger double-click action, i.e., navigate to profile
    navigate(`/user/${userId}/profile-view/${crew?.crew_id}`);
  };

  const crewDetail = (crew) => {
    return (
      <>
        <div className="crew-detail" style={{ userSelect: "none" }}>
          <Avatar
            src={
              crew?.profile_picture_url === null ||
              crew?.profile_picture_url.endsWith("default.jpg")
                ? profileAvatar
                : crew?.profile_picture_url
            }
          />
          <div className="name-icons">
            <span className="name" style={{ userSelect: "none" }}>
              {crew?.full_name}
            </span>
            <UserProfileDetailsComponent
              details={crew?.profile_details}
              userId={currentUser?.crew_id}
            />
          </div>
        </div>

        <span className="captain">
          {adminIds?.includes(crew.crew_id) ? "Captain" : "Member"}
        </span>
      </>
    );
  };

  return (
    <>
      <div className="crewlist-container">
        <Card title="Your Crew" className="crew-card">
          {isUserAdmin && (
            <div
              className="add-user"
              onClick={() => {
                setVisible(true);
              }}
            >
              <img
                className="addUser-icon"
                src={addUserIcon}
                alt="add"
                width="32px"
                height="32px"
              />
              <span>Add Crew</span>
            </div>
          )}

          {sortedCrewList?.map((crew) => {
            // Check if the current user's ID matches the crew's ID
            const isCurrentUser = currentUser?.crew_id === crew.crew_id;
            const ifEveryChoiceIsNull = crew?.profile_details?.every(
              (detail) => detail[Object.keys(detail)[0]].choice === null
            );
            if (isCurrentUser) {
              // If it's the current user, render only the crew details
              return (
                <div
                  className={`crew ${ifEveryChoiceIsNull && "no-icons"}`}
                  key={crew?.crew_id}
                  onClick={(e) => handleClick(crew, e)}
                  onDoubleClick={(e) => handleDoubleClick(crew, e)}
                >
                  {crewDetail(crew)}
                </div>
              );
            }
            const items = [];

            if (!adminIds?.includes(crew.crew_id)) {
              items.push({
                label: "Remove crew",
                key: "1",
                onClick: () => showDeleteConfirm(crew),
              });
              items.push({
                label: "Make captain",
                key: "2",
                onClick: (crew) => showMakeAdminConfirm(crew),
              });
            }
            if (adminIds?.includes(crew.crew_id)) {
              items.push({
                label: "Remove captain",
                key: "3",
                onClick: (crew) => showRemoveAdminConfirm(crew),
              });
            }

            return (
              <div
                className={`crew ${ifEveryChoiceIsNull && "no-icons"}`}
                key={crew?.crew_id}
                // onClick={(e) => handleClick(crew, e)}
                onDoubleClick={(e) => handleDoubleClick(crew, e)}
              >
                {isUserAdmin ? (
                  <Dropdown
                    overlayClassName="dropdown"
                    menu={{
                      items: items.map((item) => ({
                        ...item,
                        onClick: () => item.onClick(crew), // Pass the crew object as an argument to the onClick handler
                      })),
                    }}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <div>{crewDetail(crew)}</div>
                  </Dropdown>
                ) : (
                  <>{crewDetail(crew)}</>
                )}
              </div>
            );
          })}
        </Card>
      </div>
      <Drawer
        className="drawer-right"
        placement="right"
        onClose={() => {
          setVisible(false);
        }}
        open={visible}
        closeIcon={false}
        maskClosable
      >
        <AddCrew />
      </Drawer>
    </>
  );
};

export default CrewList;
