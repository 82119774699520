import React, { useEffect, useState } from "react";
import logo from "../../assets/images/SVG/LogoWithName.svg";
import image1 from "../../assets/images/clumsy.webp";
import image2 from "../../assets/images/stinky_img.webp";
import image3 from "../../assets/images/obsessive_img.webp";
import { Button, Layout } from "antd";
import "./Landing.styles.scss";

const { Header, Content } = Layout;
const LandingHome = ({ setLandingPage }) => {
  const [downloadLink, setDownloadLink] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.fiddlekube.cohub&pcampaignid=web_share"
      );
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDownloadLink("https://apps.apple.com/us/app/cohub/id6504533297");
    } else {
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.fiddlekube.cohub&pcampaignid=web_share"
      );
    }
  }, []);

  return (
    <Layout className="landing-page">
      <Header className="landing-nav">
        <a href={downloadLink} target="_blank" rel="noopener noreferrer">
          <span className="nav-btn">download app</span>
        </a>
        <img src={logo} className="logo" alt="logo" />
        <span className="nav-btn" onClick={() => setLandingPage(false)}>
          use on web
        </span>
      </Header>
      <Content className="landing-content">
        <div className="image-grid">
          <div className="image-item black-box blank">
            <span className="caption">THE CLUMSY ONE</span>
          </div>
          <div className="image-item">
            <img src={image1} alt="The Clumsy One" />
          </div>
          <div className="image-item blue-box blank">
            <span className="caption">THE STINKY EATER</span>
          </div>
          <div className="image-item">
            <img src={image2} alt="The Smelly Eater" />
          </div>
          <div className="image-item black-box blank">
            <span className="caption">THE OBSESSIVE CLEANER</span>
          </div>
          <div className="image-item">
            <img src={image3} alt="The Obsessive Cleaner" />
          </div>
        </div>
        <div className="bg-image">
          <span className="text">SHARING A HOME?</span>
          <Button
            className="download-btn"
            type="text"
            onClick={() =>
              window.open(downloadLink, "_blank", "noopener noreferrer")
            }
          >
            Download the app now!
          </Button>
        </div>
      </Content>
    </Layout>
  );
};

export default LandingHome;
